import React from "react"
import "../../../../../styles/GetStarted.css"
import { openPopupWidget } from "react-calendly"
import useSiteMetadata from "../../../../SiteMetaData";
function GetStarted() {
  const {calendlyDemoUrl, dashboardRegisterUrl} = useSiteMetadata()

  const bookDemo = () => openPopupWidget({ url: calendlyDemoUrl })

  const signUp = () => window.open(dashboardRegisterUrl, "_blank")

  return (
    <div className="">
      <div className="container is-fluid getStartedContainer">
        <div className="columns is-vcentered is-multiline is-mobile">
          <div className="column is-12-mobile is-6-desktop is-12-tablet getStartedCol">
            <h1 className="has-text-black  is-size-2-tablet is-size-3-mobile getStartedHeader has-text-centered-mobile">
              Digital&nbsp;
              <br className="is-hidden-tablet" />
              <span className="influencerText has-text-white px-2">
                Word of Mouth
              </span>
              &nbsp;
              <br />
              marketing solution
            </h1>

            <div className="mt-5 is-hidden-tablet has-text-centered ">
              <img
                src="assets/newLanding1.png"
                alt="landing"
                title="landing"
                className="image "
                loading="lazy"
              />
            </div>
            <h2 className="has-text-black mt-6 is-size-4-desktop is-size-5-tablet is-size-6-mobile getStartedAbout  has-text-centered-mobile">
              Your customers are already on Instagram.<br className="is-hidden-mobile"/> Auto Engage, Incentivise
              them to post, track <br className="is-hidden-mobile"/>mentions & send rewards - All on Autopilot!
            </h2>
            <div className="has-text-centered-mobile getStartedBtnContainer">
              <div className="signBtnBox">
                <button
                  className="button is-medium has-text-white getStartedBtn mt-6 is-size-6 is-uppercase"
                  onClick={signUp}
                >
                  14-DAY free trial
                </button>
{/*                <p className="trialText mt-1">NO CREDIT CARD NEEDED</p>*/}
              </div>
              <button
                className="button is-medium bookDemoBtn mt-6-desktop mt-6 is-size-6"
                onClick={() => bookDemo()}
              >
                BOOK DEMO WITH US
              </button>
            </div>
          </div>
          <div className="column is-12-mobile is-12-tablet is-6-desktop imgCol  has-text-centered is-hidden-mobile landingImage">
            <img
              src="assets/newLanding1.png"
              alt="Word of Mouth Marketing via Instagram"
              title="Word of Mouth Marketing via Instagram"
              className="image "
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default GetStarted
