import React from 'react'
import '../../../styles/InfluencerWorks.css'
import InfluencerWorksCards from './InfluencerWorksSubComponent/InfluencerWorksCards'
function InfluencerWorks() {
    return (
        <div className="influencerWorksContainer">
            <div className="container is-fluid">
                <div className="columns is-vcentered is-multiline is-mobile">
                <div className="column is-12-desktop is-12-tablet is-12-mobile has-text-centered">
                <h2 className="is-size-3-tablet has-text-black  influencerWorksHeader">
              How InfluencerBit Works?
            </h2>
            <h3 className="is-size-4 has-text-black influencerWorksSubHeader mt-4">
              4 simple steps </h3>
            
                </div>
                </div>
            </div>
            <InfluencerWorksCards/>
        </div>
    )
}

export default InfluencerWorks
