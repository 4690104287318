import React from "react"
import './comparision.css'
import celebImg from '../../../static/assets/comparision/celeb.png'
import nanoImg from '../../../static/assets/comparision/nano.png'

const Comparision = () => {

    const signUp = () => {
        window.open("https://dashboard.influencerbit.com/register","_blank")
    }

    return (
        <div className={"container is-fluid comp-container"}>
            <div className={"comp-container-box has-text-white has-text-centered"}>
                <div className={"columns has-text-centered"}>
                    <div className={"column"}>
                        <img
                            src={celebImg}
                            className={"comp-image"}
                            alt={"Celebrity Influencer"}
                            title={"Celebrity Influencer"}
                        />
                    </div>
                    <div className={"column"}>
                        <img
                            src={nanoImg}
                            className={"comp-image"}
                            alt={"Nano Influencer"}
                            title={"Nano Influencer"}
                        />
                    </div>
                </div>
                <div
                    className={"text-box mb-6"}
                    style={{
                        marginTop: '-130px',
                        paddingTop: '120px',
                        textAlign: "-webkit-center"
                    }}
                >
                    <div
                        style={{
                            maxWidth: "1280px"
                        }}
                    >
                        <h3 className={"is-size-2 is-size-5-mobile has-text-weight-bold"}>
                            We are "Influenced" by friends, not by paid influencers.
                        </h3>
                        <h4
                            className={"is-size-5 pt-4 pb-6 is-size-6-mobile"}
                        >
                            We are <b>NOT</b> an agency that's gonna sell you a bundle of Influencers.
                            <br />
                            {/*We are an Influencer management tool that captures right Influencers, gets their social insights, incentivices, tracks and reward on successful mention!*/}
                            We are a tool which incentivises, track & reward your loyal customers to mention you on Instagram!
                        </h4>
                    </div>
                </div>
                <button
                    className={"button is-size-4 is-uppercase std-button has-text-white mt-5 mb-5"}
                    onClick={signUp}
                >
                    Get Started
                </button>
            </div>
        </div>
    )
}

export default Comparision