import React from "react"
import "../../../styles/NewSoftware.css"

function NewSoftware() {
  const signUp=()=>{
    window.open("https://dashboard.influencerbit.com/register","_blank")
   }
  return (
    <div>
      <div className="container is-fluid newSoftwareContainer">
        <div className="columns is-vcentered is-multiline">
          <div className="column is-4-desktop is-12-mobile is-12-tablet">
            <h2 className="is-size-2-desktop is-size-3-tablet is-size-4-mobile has-text-black  has-text-centered-mobile newSoftwareHeader">
              No New Software,<br /> We make your existing tools smart!
            </h2>
            <p className="is-size-4-desktop is-size-5-tablet is-size-6-mobile has-text-black has-text-centered-mobile has newSoftwareInfo mt-6">
            We convert your mailing tools like Klaviyo / Mailchimp to Influencer Marketing machine.
            {/*Whatever stack you are using - Shopify, Mailchimp, Klaviyo, custom website, etc we integrate with them all.*/}
              {/*We integrate with 30+ solutions, sync your data there and convert them into influencer marketing tools.*/}
            </p>
            <div>
            <button className="button is-medium has-text-white newSoftwareBtn mt-6 is-size-6 is-hidden-mobile"  onClick={signUp}>
            GO LIVE IN 10 MIN
            </button>
            </div>
          </div>
          <div className="column is-7-desktop is-offset-1-desktop is-12-mobile is-12-tablet has-text-right">
            <img title="multibrands" loading="lazy" src="assets/multibrands1.png" alt="multibrands" className="image"/>
          </div>
        </div>
      </div>
      <div className="columns is-vcentered is-multiline">
        <div className="column is-12 has-text-centered">
          <h3 className="has-text-black  spreadsheetHeader2 is-hidden-mobile" >
            GO LIVE IN MINUTES
          </h3>
          <button className="button is-large has-text-white bigGetStartedBtn mt-6"  onClick={signUp}>GET STARTED</button>
{/*          <p className="is-size-6 sheetFooter mt-3">NO CREDIT CARD REQUIRED</p>*/}
          <div className="has-text-centered mt-6 catImageBox">
            <img title="catImg" loading="lazy"  src="assets/cat.png" alt="catImg" className="catImage image"/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewSoftware
