import React, { useState, useRef } from "react"
import "../../../../styles/InfluencerWorksCards.css"
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md"
import Slider from "react-slick"

function InfluencerWorksCards() {
  const [active, setActive] = useState(false)
  const [active1, setActive1] = useState(false)
  const [active2, setActive2] = useState(false)
  const [active3, setActive3] = useState(false)
  const slide = useRef(null)
  const slide1 = useRef(null)
  const slide2 = useRef(null)
  const slide3 = useRef(null)
  const signUp=()=>{
    window.open("https://dashboard.influencerbit.com/register","_blank")
   }
  const nextSlide = () => {
    slide.current.slickNext()
  }
  const prevSlide = () => {
    slide.current.slickPrev()
  }
  const nextSlide1 = () => {
    slide1.current.slickNext()
  }
  const prevSlide1 = () => {
    slide1.current.slickPrev()
  }
  const nextSlide2 = () => {
    slide2.current.slickNext()
  }
  const prevSlide2 = () => {
    slide2.current.slickPrev()
  }
  const nextSlide3 = () => {
    slide3.current.slickNext()
  }
  const prevSlide3 = () => {
    slide3.current.slickPrev()
  }
  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  var styling = {
    activeness: {
      backgroundColor: "#B8C0FF",
      borderRadius: "12px",
      border: "none",
      transition: "all 0.1s ease-in-out",
    },
    inactive: {
      backgroundColor: "#DFDFDF",
      borderRadius: "12px",
      border: "none",
      transition: "all 0.8s ease-in-out",
    },
  }
  return (
    <div className="">
      <div className="container is-fluid influencerWorksCardsContainer">
        <div className="columns is-vcentered is-multiline is-mobile ">
          <div className="column is-5-desktop is-12-tablet is-10-mobile">
            <h3 className="has-text-black cardHeader has-text-left is-size-2-desktop is-size-5-mobile is-size-4-tablet">
              Step 1: Ask your customers for
              their Instagram username
            </h3>
            <p className="has-text-black has-text-left is-size-5-desktop is-size-5-tablet is-size-6-mobile mt-5 cardInfo">
              We embed the Instagram Input field wherever you want. Eg: In your E-mail popup or at the checkout screen.
            </p>

            <div className="is-hidden-mobile">
              <div className="mt-6">
                <button
                  className="prevBtn"
                  onClick={() => {
                    prevSlide()
                    setActive(false)
                  }}
                  style={active ? styling.activeness : styling.inactive}
                >
                  <MdKeyboardArrowLeft className="cardArrowIcon" />
                </button>
                <button
                  className="nextBtn ml-5"
                  onClick={() => {
                    nextSlide()
                    setActive(true)
                  }}
                  style={active ? styling.inactive : styling.activeness}
                >
                  <MdKeyboardArrowRight className="cardArrowIcon" />
                </button>
              </div>
              <div className="mt-5">
                <button className="cardGetStartedBtn has-text-white"  onClick={signUp}>
                  GET STARTED
                </button>
              </div>
            </div>
          </div>
          <div className="column is-6-desktop is-offset-1-desktop is-12-tablet is-10-mobile  firstImg">
            <Slider ref={slide} {...settings}>
              <div key={1}>
                <img loading="lazy" title="Ask for Instagram Username in your Pop-up" src="assets/customerNewInsta.png" alt="card1Img" className="image" />
              </div>
              <div key={2}>
                <img loading="lazy" title="Ask for Instagram Username field at Order Checkout" src="assets/customerNewInsta1.png" alt="card1Img" className="image" />
              </div>
            </Slider>
            <div className="is-hidden-tablet">
            <div className="mt-6 ">
              <button
                className="prevBtn"
                onClick={() => {
                  prevSlide()
                  setActive(false)
                }}
                style={active ? styling.activeness : styling.inactive}
              >
                <MdKeyboardArrowLeft className="cardArrowIcon" />
              </button>
              <button
                className="nextBtn ml-5"
                onClick={() => {
                  nextSlide()
                  setActive(true)
                }}
                style={active ? styling.inactive : styling.activeness}
              >
                <MdKeyboardArrowRight className="cardArrowIcon" />
              </button>
            </div>
            <div className="mt-5">
              <button className="cardGetStartedBtn has-text-white"  onClick={signUp}>
                GET STARTED
              </button>
            </div>
            </div>
          
          </div>
        </div>
        <div className="columns is-vcentered is-multiline cardRow">
          <div className="column is-5-desktop is-12-tablet is-10-mobile is-hidden-tablet is-offset-2-mobile">
            <h3 className="has-text-black cardHeader has-text-right is-size-2-desktop is-size-5-mobile is-size-4-tablet">
              Step 2: We get <br className="is-hidden-mobile" />
              their Social Insights
            </h3>
            <p className="has-text-black has-text-right is-size-5-desktop  is-size-5-tablet is-size-6-mobile mt-5 cardInfo">
              We exactly tell you how influencial each user is on Instagram.<br className="is-hidden-mobile" /> No Guesswork!
            </p>
          </div>
          <div className="column is-7-desktop is-12-tablet is-10-mobile is-offset-2-mobile">
            <div>
              <Slider ref={slide1} {...settings}>
                <div key={1}>
                  <img loading="lazy"
                    src="assets/insights.png"
                    alt="card2Img"
                    className="image"
                    title="card2Img"
                  />
                </div>
                <div key={2}>
                  <img loading="lazy"
                    src="assets/insights1.png"
                    alt="card2Img"
                    className="image"
                    title="card2Img"
                  />
                </div>
              </Slider>

            </div>
          </div>
          <div className="column is-5-desktop is-offset-0-desktop is-12-tablet is-10-mobile is-offset-2-mobile">
            <div className="is-hidden-mobile">
              <h3 className="has-text-black cardHeader has-text-right is-size-2-desktop is-size-5-mobile is-size-4-tablet">
                Step 2: We get <br className="is-hidden-mobile" /> their Social
                Insights
              </h3>
              <p className="has-text-black has-text-right is-size-5-desktop  is-size-5-tablet is-size-6-mobile mt-5 cardInfo">
                We exactly tell you how influencial each user is on Instagram.<br className="is-hidden-mobile" /> No Guesswork!
              </p>
            </div>

            <div className="mt-5 has-text-right">
              <button
                className="prevBtn"
                onClick={() => {
                  prevSlide1()
                  setActive1(false)
                
                }}
                style={active1 ? styling.activeness : styling.inactive}
              >
                <MdKeyboardArrowLeft className="cardArrowIcon" />
              </button>
              <button
                className="nextBtn ml-5"
                onClick={() => {
                  nextSlide1()
                  setActive1(true)
                  
                }}
                style={active1 ? styling.inactive : styling.activeness}
              >
                <MdKeyboardArrowRight className="cardArrowIcon" />
              </button>
            </div>
            <div className="mt-5 has-text-right">
              <button className="cardGetStartedBtn has-text-white"  onClick={signUp}>
                GET INSIGHTS NOW
              </button>
            </div>
          </div>
        </div>
        <div className="columns is-vcentered is-multiline cardRow">
          <div className="column is-5-desktop is-12-tablet is-10-mobile">
            <h3 className="has-text-black cardHeader has-text-left is-size-2-desktop is-size-5-mobile is-size-4-tablet">
              Step 3: Automatic Reachout
            </h3>
            <p className="has-text-black has-text-left is-size-5-desktop is-size-5-tablet is-size-6-mobile mt-5 cardInfo">
              Incentivise them to post based
              <br className="is-hidden-mobile" /> on how influencial they are.
            </p>
          
            <div className="is-hidden-mobile">
              <div className="mt-6">
                <button
                  className="prevBtn"
                  onClick={() => {
                    prevSlide2()
                    setActive2(false)
                  }}
                  style={active2 ? styling.activeness : styling.inactive}
                >
                  <MdKeyboardArrowLeft className="cardArrowIcon" />
                </button>
                <button
                  className="nextBtn ml-5"
                  onClick={() => {
                    nextSlide2()
                    setActive2(true)
                  }}
                  style={active2 ? styling.inactive : styling.activeness}
                >
                  <MdKeyboardArrowRight className="cardArrowIcon" />
                </button>
              </div>
              <div className="mt-5">
                <button className="cardGetStartedBtn has-text-white"  onClick={signUp}>
                  AUTOMATE NOW
                </button>
              </div>
            </div>
          </div>
          <div className="column is-6-desktop is-12-tablet is-10-mobile  is-offset-1-desktop">
            <Slider ref={slide2} {...settings}>
              <div key={1}>
                <img title="card3Img" loading="lazy" src="assets/triggers.png" alt="card3Img" className="image" />
              </div>
              <div key={2}>
                <img title="card3Img" loading="lazy" src="assets/triggers1.png" alt="card3Img" className="image" />
              </div>
            </Slider>
            <div className="is-hidden-tablet">
            <div className="mt-6">
                <button
                  className="prevBtn"
                  onClick={() => {
                    prevSlide2()
                    setActive2(false)
                  }}
                  style={active2 ? styling.activeness : styling.inactive}
                >
                  <MdKeyboardArrowLeft className="cardArrowIcon" />
                </button>
                <button
                  className="nextBtn ml-5"
                  onClick={() => {
                    nextSlide2()
                    setActive2(true)
                  }}
                  style={active2 ? styling.inactive : styling.activeness}
                >
                  <MdKeyboardArrowRight className="cardArrowIcon" />
                </button>
              </div>
              <div className="mt-5">
                <button className="cardGetStartedBtn has-text-white"  onClick={signUp}>
                  AUTOMATE NOW
                </button>
              </div>
            </div>
          
          </div>
        </div>
        <div className="columns is-vcentered is-multiline cardRow">
          <div className="column is-4-desktop is-12-tablet is-10-mobile is-hidden-tablet  is-offset-2-mobile">
            <h3 className="has-text-black cardHeader has-text-right is-size-2-desktop is-size-5-mobile is-size-4-tablet">
              Step 4: Auto Track <br className="is-hidden-mobile" /> Media &
              Statistics
            </h3>
            <p className="has-text-black has-text-right is-size-5-desktop is-size-5-tablet is-size-6-mobile mt-5 cardInfo">
              See which user posted, their reach, likes, etc at one place{" "}
              <br className="is-hidden-mobile" /> and auto send mails & rewards
              when they post.
            </p>
          </div>
          <div className="column is-7-desktop is-12-tablet is-10-mobile  is-offset-2-mobile">
            <Slider ref={slide3} {...settings}>
              <div key={1}>
                <img title="card4Img" loading="lazy" src="assets/statistics.png" alt="card4Img" className="image" />
              </div>
              <div key={2}>
                <img title="card4Img" loading="lazy" src="assets/statistics1.png" alt="card4Img" className="image" />
              </div>
            </Slider>
          </div>
          <div className="column is-5-desktop is-offset-0-desktop is-12-tablet is-10-mobile  is-offset-2-mobile">
            <div className="is-hidden-mobile">
              <h3 className="has-text-black cardHeader has-text-right is-size-2-desktop is-size-5-mobile is-size-4-tablet">
                Step 4: Auto track <br className="is-hidden-mobile" /> posts &
                Send rewards
              </h3>
              <p className="has-text-black has-text-right is-size-5-desktop is-size-5-tablet is-size-6-mobile mt-5 cardInfo">
                See which user posted, their reach, likes, etc at one place and auto send rewards
                when they post.
                {/*Track all media, their reach, likes, etc at one place and auto send mails &*/}
                {/*rewards when they post.*/}
              </p>
            </div>

            <div className="mt-5 has-text-right">
              <button
                className="prevBtn"
                onClick={() => {
                  prevSlide3()
                  setActive3(false)
                }}
                style={active3 ? styling.activeness : styling.inactive}
              >
                <MdKeyboardArrowLeft className="cardArrowIcon" />
              </button>
              <button
                className="nextBtn ml-5"
                onClick={() => {
                  nextSlide3()
                  setActive3(true)
                }}
                style={active3 ? styling.inactive : styling.activeness}
              >
                <MdKeyboardArrowRight className="cardArrowIcon" />
              </button>
            </div>
            <div className="mt-5 has-text-right">
              <button className="cardGetStartedBtn has-text-white"  onClick={signUp}>
                START TRACKING
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfluencerWorksCards
