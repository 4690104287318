import React from "react"
import "../../../styles/InstagramShoutout.css"
import automateText from "../../../../static/assets/automateText.png"
function InstagramShoutout() {
  const data = [
    // {
    //   text: "Incentivise customers to post",
    // },
    // {
    //   text: "Collect Instagram handles",
    // },
    {
      text: "Incentivize customers to post",
    },
    // {
    //   text: "Track when your customers post",
    // },
    {
      text: "Track mentions, Impressions & Sales!",
    },
    // {
    //   text: "Incentivise customers to post",
    // },
    // {
    //   text: "Overall statistics, Engagements & Reach reports",
    // },
    {
      text: "Auto-send rewards on Instagram mention",
    },
    {
      text: "Overall statistics, Engagements & Reach reports",
    },

    // {
    //   text: "Track statistics, engagements & reach",
    // },
    // {
    //   text: "Auto-send coupons on Instagram mention",
    // },
  ]
  const signUp = () => {
    window.open("https://dashboard.influencerbit.com/register", "_blank")
  }
  return (
    <div>
      <div className="container is-fluid instagramShoutoutContainer">
        <div className="columns is-multiline">
          <div className="column is-12-mobile is-12-tablet is-6-widescreen is-offset-3-widescreen is-6-desktop is-offset-3-desktop has-text-centered instaShoutoutBox">
            <h2 className="is-size-2-desktop is-size-3-tablet is-size-3-mobile has-text-black instaShoutoutHeader">
              We maintain your army of <br /> Nano-Micro Influencers
            </h2>
            <p className="instaShoutoutInfo has-text-black is-size-4-desktop is-size-5-tablet is-size-6-mobile">
              Manually tracking 500+ small influencers <br />is just not possible
              without automation
            </p>

          </div>
          <div
            className=" column is-12  automateTextBox"

          >
            <img
              src={automateText}
              alt="automateTextImg"
              loading="lazy"
              title="automateTextImg"
              className="image automateTextImg"
            />
          </div>
          {data.map(d => {
            return (
              <div className="column is-12">
                <div className="instaShoutoutCard has-text-centered">
                  <h3 className=" instaShoutoutCardText has-text-white is-size-4-tablet is-size-4-mobile">
                    {d.text}
                  </h3>
                </div>
              </div>
            )
          })}
{/*          <div className="column is-12 has-text-centered">
            <button
              onClick={signUp}
              className="button is-medium bookDemoBtn mt-6-desktop mt-5 is-size-6"
            >
              GET STARTED
            </button>
          </div>*/}
        </div>
      </div>
    </div>
  )
}

export default InstagramShoutout
