import React, { useRef } from "react"
import "../../../styles/InfluencerWeighing.css"
import { StaticImage } from "gatsby-plugin-image"
import { MdKeyboardArrowRight } from "react-icons/md"
import * as animationData from "./animation.json"
import Lottie from "react-lottie"
import { openPopupWidget } from "react-calendly"
import { useEffect } from "react"
import UseOnScreen from "../../UseOnScreen"
import useSiteMetadata from "../../SiteMetaData";

function InfluencerWeighing() {
  const wref = useRef(null)
  const {calendlyDemoUrl, dashboardRegisterUrl} = useSiteMetadata()
  const ref = useRef()
  const isVisible = UseOnScreen(ref)

  useEffect(() => {
    console.log(isVisible, "visible")
    if (isVisible) {
      setTimeout(()=>{
        wref.current.play()
      },1500)
   
    }
  }, [isVisible])

  const bookDemo = () => openPopupWidget({ url: calendlyDemoUrl })

  const defaultOptions = {
    loop: false,
    autoplay: false,
    count: 1,
    speed: 1,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  return (
    <div>
      <div
        className="container  influencerWeighingContainer"
       
      >
        <div className="columns  is-vcentered is-multiline is-mobile">
          <div className="column is-5-desktop is-5-tablet is-5-mobile">
            <h2 className="is-size-3-desktop is-size-6-mobile is-size-4-tablet has-text-black has-text-centered influencerWeighingHeader">
              500+ LOYAL CUSTOMERS <br className="is-hidden-mobile" />
              POSTING ABOUT YOUR BRAND
            </h2>
          </div>
          <div className="column is-2-desktop is-2-tablet is-2-mobile">
            <h2 className="is-size-2-desktop is-size-4-mobile is-size-3-tablet  has-text-black has-text-centered influencerWeighingHeader">
              <MdKeyboardArrowRight className="arrowIcon" />
            </h2>
          </div>
          <div className="column is-5-desktop is-5-tablet is-5-mobile">
            <h2 className="is-size-3-desktop is-size-6-mobile is-size-4-tablet  has-text-black has-text-centered influencerWeighingHeader">
              2-3 RANDOM
              <br />
              CELEBRITY INFLUENCERS
            </h2>
          </div>
        </div>
        <div className="columns is-vcentered is-multiline">
          <div
              className="column is-10 has-text-centered mt-6 is-offset-1"
              ref={ref}
          >
            <Lottie
              options={defaultOptions}
              className="weighingImage"
              ref={wref}
            />
            <p
              className="has-text-left has-text-black is-size-4-desktop is-size-6-mobile is-size-5-tablet influencerWeighingText"
            >
              <span className="weighingSpan">Problem:</span> Manually maintaining 500+ Influencers is too hard 😔 <br />
              <span className="weighingSpan">Solution:</span> The only tool you need to manage this big army who already loves your product! 🤩
              <div className="mt-3 weighingListContainer">
                <ul style={{"list-style-type": "none"}}>
                  <li>✅ &nbsp;  Capture Perfect Influencers from your Customer Community</li>
                  <li>✅ &nbsp; Incentivising them to mention you on Instagram</li>
                  <li>✅ &nbsp; Track their Posts / Stories when they Post and Make reports </li>
                  <li>✅ &nbsp; Auto-Send thank you Mail / Rewards / Coupons</li>
                </ul>
              </div>
            </p>
            <div className="has-text-centered  mt-6 ">
              <button
                className="button is-medium influencerWeighingBookBtn "
                onClick={() => bookDemo()}
              >
                BOOK DEMO
              </button>
            </div>
            {/*<p className="is-size-4-desktop is-size-5-tablet is-size-6-mobile mt-4 weighingBottomText">*/}
            {/*  Your marketing strategy will change after this 15 min call &nbsp;*/}
            {/*  <StaticImage src="../../../../static/assets/winkingFace.svg" />{" "}*/}
            {/*</p>*/}
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfluencerWeighing
