import React from "react"
import "../../../styles/InfluencerBudget.css"

const signUp=()=>{
  window.open("https://dashboard.influencerbit.com/register","_blank")
 }
function InfluencerBudget() {
  return (
    <div>
      <div className="container is-fluid influencerBudgetContainer">
        <div className="columns is-vcentered is-multiline is-mobile">
          <div className="column is-7-desktop is-8-mobile is-12-tablet">
            <h2
              className="has-text-white influencerBudgetHeader is-size-2-desktop is-size-3-tablet is-size-5-mobile has-text-left">
              With less than <span className="budgetSpan">1% of your ad budget</span>,<br
              className="is-hidden-mobile" /> try this equally powerful
              Customer acquisation tool
            </h2>
            <button className="mt-6 getStartedBtn has-text-white is-size-6 budgetBtn is-hidden-mobile" onClick={signUp}>GET STARTED NOW
            </button>
          </div>
          <div className="column is-5-desktop is-4-mobile is-12-tablet has-text-centered customerImgContainer ">
 
            <img alt="budgetImg" title="budgetImg" className="image" src="assets/customerBudget1.png" loading="lazy" />

          </div>
        </div>
      </div>
    </div>
  )
}

export default InfluencerBudget
