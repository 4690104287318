import React from "react"
import "../../../styles/InfluencerGram.css"
import { IoMdMail } from "react-icons/io"
import { RiInstagramLine } from "react-icons/ri"
import { openPopupWidget } from "react-calendly"
import { useState } from "react"
import useSiteMetadata from "../../SiteMetaData";
function InfluencerGram() {
  const {calendlyDemoUrl, dashboardRegisterUrl} = useSiteMetadata()
  const [userData,setUserData]=useState({
    email:"",
    instaUsername:""
  })
  const onHandleChange=(e)=>{
      setUserData({...userData,[e.target.name]:e.target.value})
  }
  const url = calendlyDemoUrl

  const formSubmit = (e) => {
    e.preventDefault()
    const prefill={
      email:userData.email,
      customAnswers: {
        "a1": userData.instaUsername
      }
    }
    openPopupWidget({ url,prefill })
    setUserData({...userData,email:"",instaUsername:""})
  }

  const signUp=()=>{window.open(dashboardRegisterUrl,"_blank")}

  return (
    <div>
      <div className="container influencerGramContainer is-fluid">
        <div className="columns is-vcentered is-multiline is-mobile">
          <div className="column is-6-desktop is-12-mobile is-12-tablet gramImageContainer">
            <img src="assets/insta.png" className="gramImage" alt="gramImg" loading="lazy" title="gramImg" />
          </div>
          <div className="column is-6-desktop is-12-mobile is-12-tablet ">
            <h2 className="is-size-3-desktop is-size-4-tablet is-size-6-mobile gramHeader has-text-centered">
              If people love to “<span className="gramHeaderSpan">Gram</span> ”
              your product,
              let us prove you how you can 10X your ROI on autopilot
            </h2>
            <div className="formContainer  ">
              <form onSubmit={formSubmit}>
                <label className="has-text-black is-size-5-desktop is-size-6-mobile is-size-6-tablet gramLabel ml-5" htmlFor="email">
                  EMAIL
                </label>
                <div className="control has-icons-left has-icons-right gramInputContainer  mt-2">
                  <input
                    className="input gramEmailInput is-size-5-desktop is-size-6-mobile"
                    type="email"
                    placeholder="jade@brand.com"
                    name="email"
                    required
                    onChange={onHandleChange}
                    autoComplete="off"
                  />
                  <span className="icon is-size-4 is-left ml-5 mr-5">
                    <IoMdMail className="gramEmailIcon mr-3" />
                  </span>
                </div>
                <div className="mt-6">
                  <label className="has-text-black is-size-5-desktop gramLabel ml-5 is-size-6-mobile is-size-6-tablet " htmlFor="instagramUsername">
                    BRANDS'S INSTAGRAM USERNAME
                  </label>
                  <div className="control has-icons-left has-icons-right gramInputContainer mt-2">
                    <input
                      className="input gramInstaInput is-size-5-desktop is-size-6-mobile"
                      type="text"
                      placeholder="awesome_brand"
                      name="instaUsername"
                      onChange={onHandleChange}
                      required
                       autoComplete="off"
                    />
                    <span className="icon is-size-4 is-left ml-5 mr-5">
                      <RiInstagramLine className="gramEmailIcon mr-3"  />
                    </span>
                  </div>
                </div>
                  <div className="has-text-centered mt-6">
                  <button className="gramButton has-text-white is-size-6"   
                >BOOK A DEMO NOW</button>
              </div>
              </form>
           
              <p className="has-text-centered gramSubText is-size-5-desktop is-size-6-mobile is-size-6-tablet mt-5">We’ll come fully prepared with reports & charts!</p>
            </div>
          </div>

        </div>
      </div>


    </div>
  )
}

export default InfluencerGram
