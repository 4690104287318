import React from "react"
import Landing from "../components/Landing/Landing"
import PageSeo from "../components/SEO"

function index() {
  return (
      <div>
        <PageSeo/>
        <Landing />
      </div>

  )
}

export default index
