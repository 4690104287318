import React, { useState } from "react"
import "../../../styles/InfluencerCalculator.css"
import CustomerModel from "./InfluencerCalculatorSubComponent/CustomerModel"
import VisitorModel from "./InfluencerCalculatorSubComponent/VisitorModel"
import calcImage from '../../../../static/assets/calcImage.png'
function InfluencerCalculator() {
  const [model, setModel] = useState("visited")

  const setModelFunction = data => {
    setModel(data)
  }
  var styling = {
    activeness: {
      borderTop: "15px solid #B8C0FF",
      transition: "all 0.8s ease-in-out",
      color: "black",
    },
    inactive: {
      borderTop: "15px solid #FFFFFF",
      color: "#5D5D5D",
    },
  }
  return (
    <div>
      <div className="container is-fluid influencerCalculatorContainer">
        <div className="columns is-vcentered is-multiline is-mobile">
          <div className="column is-12-desktop is-12-tablet is-12-mobile "> 
          
            <h2 className="has-text-centered has-text-black is-size-2-desktop is-size-3-tablet is-size-4-mobile influencerCalculatorHeader">
            
              Let's <span className="calculatorHeaderSpan">Compare the ROI </span> with Paid ads <br /><br />
              {/*How much are you <span className="calculatorHeaderSpan">missing out</span>?*/}
              {/*Calculate how much you are <span className="calculatorHeaderSpan"> missing out</span> <br className="is-hidden-mobile"/>every*/}
              {/*month by NOT doing this*/}
            </h2>
            {/*<div className=" calcImageBox">*/}
            {/*<img src={calcImage} alt="1 Influencer gets you 15 clicks" title="calculator-image" className="image calcImage"/>*/}
            {/*</div>*/}

          </div>
          {/*<div className="column is-3-desktop is-6-tablet is-5-mobile modelColumn has-text-centered">*/}
          {/*  <h3*/}
          {/*    className=" is-size-4-tablet is-size-6-mobile influencerCalculatorSubHeader pt-3"*/}
          {/*    onClick={() => setModelFunction("visited")}*/}
          {/*    style={*/}
          {/*      model === "visited" ? styling.activeness : styling.inactive*/}
          {/*    }*/}
          {/*  >*/}
          {/*    Customer Model*/}
          {/*  </h3>*/}
          {/*</div>*/}
          {/*<div className="column is-3-desktop is-6-tablet is-5-mobile modelColumn has-text-centered">*/}
          {/*  <h3*/}
          {/*    className="is-size-4-tablet is-size-6-mobile influencerCalculatorSubHeader pt-3"*/}
          {/*    onClick={() => setModelFunction("customer")}*/}
          {/*    style={*/}
          {/*      model !== "visited" ? styling.activeness : styling.inactive*/}
          {/*    }*/}
          {/*  >*/}
          {/*    Visitor Model*/}
          {/*  </h3>*/}
          {/*</div>*/}

        </div>
        {model === "visited" ? <CustomerModel />:<VisitorModel />}

      </div>
    </div>
  )
}

export default InfluencerCalculator
