import React, { useState } from "react"
import "../../../../styles/VisitorModel.css"
import tryit from "../../../../../static/assets/tryityourself.png"
var styling = {
  activeness: {
    color: "#006DAA",
    fontWeight: "550",
  },
  inactive: {
    color: "black",
  },
}
function VisitorModel() {
  const signUp = () => {
    window.open("https://dashboard.influencerbit.com/register", "_blank")
  }
  const [data, setData] = useState(1000)
  const [influencers, setInfluencers] = useState(150)
  const influencersChanged = e => {
    setData(e.target.value)
    setInfluencers(parseInt(e.target.value * 0.005 * 30))
  }

  return (
    <div>
      <div className="container is-fluid visitorContainer">
        <div className="columns is-vcentered is-multiline is-mobile">
          <div className="column is-12">
            <div className="visitorCard ">
              <h2 className="has-text-centered has-text-white  visitorCardHeader">
                Average Daily Visitors
              </h2>
              <div className="is-pulled-right is-hidden-touch tryitImgBox" style={{marginTop:"-90px"}}>
                 <img src={tryit} alt="" className="image tryItImage" />
              </div>

              <div className="inputBox">
                <input
                  value={data}
                  className="input mt-6 modelInput has-text-centered "
                  type="number"
                  onChange={influencersChanged}
                />
              </div>

              <p className="has-text-centered has-text-white  mt-3 visitorCardInfo">
                ~ 0.5% give their Instagram username
              </p>
              <h3 className="has-text-centered  mt-5 influencers">
                {influencers}+
              </h3>
              <p className="has-text-centered mt-3 visitorCardBottom is-size-4-desktop is-size-6-mobile is-size-5-tablet">
                Loyal Customers posting about you in a month
              </p>
              <br />
              <p className="has-text-centered mt-3 has-text-white is-size-4-desktop visitorCardInfo">
                Let's compare this with Ads<br />
                ~15 Clicks per influencer * $2 CPC = ${influencers*2*15}
              </p>
            </div>
          </div>
          {/* <div className="column is-3-desktop is-6-mobile is-6-tablet is-offset-1-desktop">
            <h3 className="has-text-black has-text-left  dailyHeader">
              Daily Site <br /> Visitors <br className="is-hidden-desktop" />&nbsp;
            </h3>
            <h3 className=" has-text-left  dailyValue " style={data>=0&data<=150?styling.activeness:styling.inactive}>
              About 100
            </h3>
            <h3 className=" has-text-left  dailyValues" style={data>150&data<=2500?styling.activeness:styling.inactive}>
              About 1,000
            </h3>
            <h3 className=" has-text-left  dailyValues"  style={data>2500&data<=7500?styling.activeness:styling.inactive}>
              About 5,000
            </h3>
            <h3 className=" has-text-left  dailyValues" style={data>7500?styling.activeness:styling.inactive}>
              10,000+
            </h3>
            <button className="button is-medium has-text-white getStartedBtns mt-6 is-size-6 is-hidden-mobile" onClick={signUp}>
            START FOR FREE
            </button>
          </div>
          <div className="column is-3-desktop is-6-mobile is-6-tablet  averageCol">
            <h3 className="has-text-black has-text-left  dailyHeader">
              Average <br className="is-hidden-tablet" />  Impressions <br /><br/>
            </h3>
            <h3 className="has-text-left  dailyValue" style={data>=0&data<=150?styling.activeness:styling.inactive}>
          1k+
            </h3>
            <h3 className=" has-text-left  dailyValues" style={data>150&data<=2500?styling.activeness:styling.inactive}>
            75k+
            </h3>
            <h3 className=" has-text-left  dailyValues" style={data>2500&data<=7500?styling.activeness:styling.inactive}>
           250k+
            </h3>
            <h3 className=" has-text-left  dailyValues" style={data>7500?styling.activeness:styling.inactive}>
              750k+
            </h3>
            <h2 className=" is-medium has-text-white hiddenText is-size-6 disabled is-hidden-mobile">
              GET STARTED
            </h2>
          </div>
         <div className="column is-12 is-hidden-tablet">
         <div className="mt-5 has-text-centered ">
          <button className="button is-medium has-text-white getStartedBtns is-size-6">
            GET STARTED
            </button>
          </div>
         </div> */}
        </div>
      </div>
    </div>
  )
}

export default VisitorModel
