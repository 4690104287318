import React from "react";
import useSiteMetadata from "../../SiteMetaData";
import IFrameComponent from "../../IFrameComponent";

const DemoSection = () => {
  const {dashboardRegisterUrl} = useSiteMetadata()

  const signUp = () => window.open(dashboardRegisterUrl,"_blank")

  return(
      <div className={"mb-6"}>
        <div
            className="container is-fluid influencerBudgetContainer"
        >
          <div className="columns is-vcentered is-multiline mb-4">
            <div className="column is-5-desktop is-12-tablet has-text-centered m-0 p-0">
              <IFrameComponent />
            </div>
            <div className={"column is-1-desktop is-hidden-touch"} />
            <div className="column is-5-desktop is-12-tablet ">
              <h2
                className="py-5 has-text-white has-text-weight-bold is-size-2-fullhd is-size-3-tablet is-size-4-mobile has-text-left"
              >
                Want to see us in <span className={"has-color-chalk"}>Action</span>?
                <br />
              </h2>
              <h4
                className={"has-text-white is-size-4-desktop is-size-5-tablet is-size-6-mobile has-text-left"}
              >
                Watch this 1 minute explainer video
              </h4>
            </div>
          </div>
        </div>
      </div>
  )
}

export default DemoSection
