import React from 'react'
import LandingNavbar from '../Navbar/LandingNavbar'
import '../../../styles/IntroSection.css'
import GetStarted from './IntroSectionSubComponent/GetStarted/GetStarted'
import Sponsers from '../Sponsers/Sponsers'
function IntroSection() {
    return (
        <div className="introContainer">
            <LandingNavbar/>
            <GetStarted/>
            {/* <Sponsers/> */}
        </div>
    )
}

export default IntroSection
