import React from "react"
import InfluencerWeighing from "../LandingSubComponents/InfluencerWeighing/InfluencerWeighing"
import IntroSection from "../LandingSubComponents/IntroSection/IntroSection"
import "../../styles/Landing.css"
import InfluencerWorks from "../LandingSubComponents/InfluencerWorks/InfluencerWorks"
import InfluencerCalculator from "../LandingSubComponents/InfluencerCalculator/InfluencerCalculator"
import NewSoftware from "../LandingSubComponents/NewSoftware/NewSoftware"
import InfluencerBudget from "../LandingSubComponents/InfluencerBudget/InfluencerBudget"
import InfluencerGram from "../LandingSubComponents/InfluencerGram/InfluencerGram"
import InfluencerFooter from "../LandingSubComponents/InfluencerFooter/InfluencerFooter"
import InstagramShoutout from "../LandingSubComponents/InstagramShoutout/InstagramShoutout"
import Comparision from "../Comparision";
import DemoSection from "../LandingSubComponents/DemoSection";

function Landing() {
 
  return (
    <div className="landingContainer">
      <IntroSection />
      <InstagramShoutout/>
{/*       <InfluencerWeighing />*/}
      <DemoSection />
      <Comparision />
      <InfluencerBudget/>
      <InfluencerCalculator/>
      <InfluencerWorks />
      <InfluencerGram/>
      <NewSoftware/>
      <InfluencerFooter/>
    </div>
  )
}

export default Landing
